<script setup>
import mushroom from "cem-probe-api";
import { defineProps, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { selectVlan } from "@/stores/changeSelectVlan";
import { datePicker } from "@/stores/datePicker";
import MethodService from "@/service/MethodService";
const storeDatePicker = datePicker();
const storeSelectVlan = selectVlan();
const route = useRoute();
const router = useRouter();
const props = defineProps({
  items: Array,
});
const nameService = ref("");
const nameProbe = ref("");
const nameVlan = ref("VLAN : ALL");
const urlVlan = ref("");
const nameUsecases = ref("");
const nameMenuParent = ref(""); // menu cha dynamic
const nameMenuChild = ref(""); // menu con dynamic
const nameTableScreen = ref("");

const getNameService = async () => {
  try {
    const response = await mushroom.service.findByIdAsync(
      {
        id: route.params?.id,
      },
      { enabledCache: false }
    );
    nameService.value = response.result.name;
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getNameDynamic = async () => {
  try {
    const response = await mushroom.dynamic_dashboard.findByIdAsync({
      id: route.params?.id,
    });
    if (response.result?.menu_id) {
      const res = await mushroom.menu.findByIdAsync({
        id: response.result?.menu_id,
      });
      if (!res.result?.parent_id) {
        nameMenuParent.value = res.result.label;
      } else {
        nameMenuChild.value = res.result.label;
        const resMenuParent = await mushroom.menu.findByIdAsync({
          id: res.result.parent_id,
        });
        if (resMenuParent.result)
          nameMenuParent.value = resMenuParent.result.label;
      }
    }
  } catch (e) {
    console.error("Có lỗi: %o", e);
  }
};
const getNameUsecases = async () => {
  if (route.query.ip) {
    nameUsecases.value = "- " + route.query.ip;
  }
};
const trimVlan = (str) => {
  if (!str) return;
  return storeSelectVlan.getValueVlan.substr(
    storeSelectVlan.getValueVlan.lastIndexOf(":") + 1
  );
};
const hashUrl = () => {
  if (storeSelectVlan.getValueVlan === "all") {
    router.push({
      path: "/service-health",
      query: {
        start_date: MethodService.formatDateYYYYMMDD(
          storeDatePicker.showDatePicker[0]
        ),
        stop_date: MethodService.formatDateYYYYMMDD(
          storeDatePicker.showDatePicker[1]
        ),
      },
    });
  } else {
    router.push({
      path: "/service-health",
      query: {
        start_date: MethodService.formatDateYYYYMMDD(
          storeDatePicker.showDatePicker[0]
        ),
        stop_date: MethodService.formatDateYYYYMMDD(
          storeDatePicker.showDatePicker[1]
        ),
        vlan: trimVlan(storeSelectVlan.getValueVlan), // xem lại giá trị vlan từ store
      },
    });
  }
};

const getNameTableScreen = async () => {
  try {
    const response = await mushroom.table_screen.findByIdAsync({
        id: route.params?.id,
    }, { enabledCache: false });
    nameTableScreen.value = response.result.name;
  }
  catch (e) {
      console.error("Có lỗi: %o", e);
  }
};

watch(
  () => storeSelectVlan.getValueVlan,
  (valueVlan) => {
    valueVlan.indexOf("VLAN") !== -1
      ? (nameVlan.value = valueVlan)
      : (nameVlan.value =
          "VLAN : " + (valueVlan === "all" ? "ALL" : valueVlan));
    urlVlan.value = window.location.search;
  }
);
watch(
  () => route.params?.id,
  async () => {
    if (route.name === "DichVu") await getNameService();
    if (route.name === "Usecases621") await getNameUsecases();
    if (route.name === "Dynamic") await getNameDynamic();
    if (route.name === "TableScreen") await getNameTableScreen();
  }
);

onMounted(async () => {
  if (route.name === "DichVu") await getNameService();
  if (route.name === "Usecases621") await getNameUsecases();
  if (route.name === "Dynamic") await getNameDynamic();
  if (route.name === "TableScreen") await getNameTableScreen();
  nameProbe.value = localStorage.getItem("probeName");
});
</script>

<template>
  <!-- start page title -->
  <div class="row d-none d-lg-block">
    <div class="col-12">
      <div
        class="page-title-box d-sm-flex align-items-center justify-content-between"
      >
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <li
              class="breadcrumb-item"
              v-for="(item, index) in props.items"
              :key="index"
              :class="{ active: item[0] }"
            >
              <route-link
                :to="{ path: `${item.href}` }"
                class="breadcrumb-link"
                >{{ item.text }}</route-link
              >
            </li>
            <li class="breadcrumb-item" v-if="route.name === 'ServiceHealth'">
              <route-link :to="{ path: `` }" class="breadcrumb-link-page">{{
                nameProbe
              }}</route-link>
            </li>

            <li
              class="breadcrumb-item"
              v-if="route.name === 'ServiceHealth'"
              @click="hashUrl"
            >
              <route-link :to="{ path: `` }" class="breadcrumb-link-page">{{
                nameVlan
              }}</route-link>
            </li>
            <li
              class="breadcrumb-item"
              v-if="route.name === 'TableScreen'"
            >
              <route-link :to="{ path: `` }" class="breadcrumb-link-page">{{
                nameTableScreen
              }}</route-link>
            </li>
            <li class="breadcrumb-item" v-if="route.name === 'DichVu'">
              <route-link :to="{ path: `` }" class="breadcrumb-link-page">{{
                nameService
              }}</route-link>
            </li>
            <li
              class="breadcrumb-item"
              v-if="route.name === 'Dynamic' && nameMenuParent"
            >
              <route-link
                :to="{ path: `` }"
                :class="
                  nameMenuChild ? 'breadcrumb-link' : 'breadcrumb-link-page'
                "
                >{{ nameMenuParent }}</route-link
              >
            </li>
            <li
              class="breadcrumb-item"
              v-if="route.name === 'Dynamic' && nameMenuChild"
            >
              <route-link :to="{ path: `` }" class="breadcrumb-link-page">{{
                nameMenuChild
              }}</route-link>
            </li>

            <li class="breadcrumb-item" v-if="route.name === 'Usecases621'">
              <route-link :to="{ path: `` }"
                >Usecases 6.2.1 {{ nameUsecases }}</route-link
              >
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
  <!-- end page title -->
</template>
<style scoped lang="scss">
.breadcrumb-link,
.breadcrumb-link-page {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
  border-radius: 8px;
  padding: 4px 8px 4px 8px;
  &:hover {
    background-color: #f2f2f2;
  }
}
.breadcrumb-item {
  cursor: pointer;
}
.breadcrumb-link {
  color: #a4a4a4;
}
.breadcrumb-link-page {
  color: #1c1c1c;
}
</style>
