import { defineStore } from "pinia";
import mushroom from "cem-probe-api";

export const listMenu = defineStore({
  id: "listMenu",
  state: () => ({
    menuServices: [],
    menuUsecases: [],
  }),
  actions: {
    async getListMenuService() {
      try {
        const responseRoles = await mushroom.$auth.meAsync();
        const response = await mushroom.service.listAsync({
          filters: responseRoles.result.roles.includes("Admin")
            ? ["disabled!=true", "is_menu_hidden!=true"]
            : ["is_menu_hidden!=true"],
          limit: 100,
        });
        if (response.result) {
          response.result.forEach((item) => {
            if (item.avatar_id) {
              item.url = mushroom.$file.linkBuilder.thumb
                .id(item.avatar_id)
                .stretch(20, 20)
                .build();
            }
          });
          this.menuServices = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },

    async getListMenuUsecases() {
      try {
        const responseRoles = await mushroom.$auth.meAsync();
        const response = await mushroom.usecase.listAsync({
          filters: responseRoles.result.roles.includes("Admin")
            ? ["disabled!=true", "is_menu_hidden!=true"]
            : ["is_menu_hidden!=true"],
          limit: 100,
        });
        if (response.result) {
          response.result.forEach((item) => {
            if (item.avatar_id) {
              item.url = mushroom.$file.linkBuilder.thumb
                .id(item.avatar_id)
                .stretch(20, 20)
                .build();
            }
          });
          this.menuUsecases = response.result;
        }
      } catch (e) {
        console.error("Có lỗi: %o", e);
      }
    },
  },
  getters: {
    showListMenuService(state) {
      return state.menuServices;
    },
    showListMenuUsecases(state) {
      return state.menuUsecases;
    },
  },
});
